import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import success from "../images/success.svg";
import AniLink from "gatsby-plugin-transition-link/AniLink";

function ThankYouPage() {
  return (
    <Layout>
      <SEO
        keywords={[`Greg's thank you page`]}
        title="Thank You | Submission Successful"
      />

      <section id="hero" className="pb-12 shadow-xl">
        <div className="text-center max-w-6xl mx-auto text-white">
          <h1 className="text-6xl">Thank you!</h1>
          <p>
            Your info was successfully submitted. We&apos;ll reach out shortly to
            connect.
          </p>
          <button className="bg-transparent hover:text-gray-300 hover:border-gray-300 text-white font-semibold hover:text-white py-2 px-4 border border-white rounded px-8 py-2 my-6">
            <AniLink fade duration={0.8} to="/">
              Take me back to the home page
            </AniLink>
          </button>
          <figure>
            <img className="mx-auto my-8" src={success} alt="success" />
          </figure>
        </div>
      </section>
    </Layout>
  );
}

export default ThankYouPage;
